.text-best-choice {
  color: #ffffff;
  font-family: "Montserrat", Sans-serif;
  font-size: 43px;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 49px;
}

div#BestChoice {
  background: #e9bf49;
  height: 300px;
}
.shadow {
  box-shadow: -1px 18px 51px 15px rgba(0, 0, 0, 0.5) !important;
}
#BestChoice img.top {
  margin-top: -90px;
  z-index: 666 !important;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .text-best-choice {
    color: #ffffff;
    font-family: "Montserrat", Sans-serif;
    font-size: 26px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 40px;
  }
}

@media only screen and (max-width: 992px) {
  .text-best-choice {
    color: #ffffff;
    font-family: "Montserrat", Sans-serif;
    font-size: 26px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 40px;
  }

  h4.title-top {
    letter-spacing: 1px;
    font-family: cursive;
  }

  div#BestChoice {
    background: #e9bf49;
    height: 230px;
    margin-top: -30px;
  }
}

@media only screen and (max-width: 1024px) {
}
