div#Service {
  background: #d8d8d8;
  padding: 115px 0;
}

.fa-solid {
  font-size: 72px;
}
.circle-icon {
  background: #ffc107;
  padding: 18px;
  border-radius: 50%;
}

.t-left {
  margin-left: 50px;
}

.space-gap {
  padding: 50px;
}

p {
  font-family: sans-serif;
}

.margin {
  margin-left: 45px;
}

@media only screen and (max-width: 768px) {
  .circle-icon {
    background: #ffc107;
    padding: 10px;
    border-radius: 50%;
  }

  .fa-solid {
    font-size: 32px;
  }

  .col-sm-2.col-md-2 {
    text-align: center;
  }

  div#Service {
    background: #d8d8d8;
    padding: 60px 0;
  }
  .margin {
    margin-left: 0px;
    text-align: center;
  }
}

@media only screen and (max-width: 992px) {
  .circle-icon {
    background: #ffc107;
    padding: 20px;
    border-radius: 50%;
  }
  .fa-solid {
    font-size: 48px;
  }
}

@media only screen and (max-width: 1200px) {
}
