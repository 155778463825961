div#Certifications {
  background: url(https://dyebold.com/wp-content/uploads/2022/10/rug-restoration.webp)
    no-repeat;
  width: 100%;
  height: 30vh;
  background-size: cover;
}
div#Certifications .row {
  padding: 35px;
}
div#Certifications h4.title-top {
  letter-spacing: 5px;
  font-family: cursive;
  color: #fff;
}

div#Certifications .overlay {
  background-color: #000000;
  opacity: 0.75;
  transition: 0.3s, border-radius 0.3s, opacity 0.3s;
  width: 100%;
  height: 30vh;
}

i.fas.fa-trophy {
  font-size: 72px;
  color: #000;
  background: #ffffff;
  padding: 15px;
  border-radius: 50%;
}

@media only screen and (max-width: 1200px) {
  i.fas.fa-trophy {
    font-size: 60px;
  }
  .text-best-choice {
    font-size: 22px;
    line-height: 25px;
  }
  div#Certifications h4.title-top {
    letter-spacing: 3px;
  }
  .main-area {
    padding: 20px;
  }
}

@media only screen and (max-width: 992px) {
  i.fas.fa-trophy {
    font-size: 48px;
  }
  .text-best-choice {
    font-size: 18px;
    line-height: 20px;
  }
  div#Certifications h4.title-top {
    letter-spacing: 1px;
  }
  .main-area {
    padding: 20px;
  }
}

@media only screen and (max-width: 768px) {
  i.fas.fa-trophy {
    font-size: 22px;
  }
  .text-best-choice {
    font-size: 18px;
    line-height: 22px;
    margin-top: 20px;
  }
  div#Certifications h4.title-top {
    letter-spacing: 1px;
    font-size: 14px;
  }
  .main-area {
    padding: 20px;
  }

  div#Certifications .overlay {
    height: 28vh;
  }

  div#Certifications {
    height: 28vh;
  }
  div#Certifications .row {
    text-align: center;
    padding: 10px;
  }
}
