#TopBar .social-media {
  max-width: 1000px;
  width: 100%;
  margin-top: 32px;
}

i.fa.fa-phone {
  font-size: 30px;
  margin-right: 14px;
  background: white;
  padding: 20px;
  border-radius: 50%;
  color: #ffc107;
}

.call-now {
  font-size: 24px;
}

.bg-warning {
  background-color: #ffc107 !important;
  height: 90px;
}

i.fa.fa-phone {
  font-size: 20px;
  background: white;
  padding: 16px;
  border-radius: 50%;
  color: #ffc107;
}

.call-now {
  font-size: 19px;
  font-weight: 900;
  color: #000;
}

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (tablets, 768px and up) */
@media (max-width: 768px) {
}

/* Medium devices (desktops, 992px and up) */
@media (max-width: 992px) {
  div#TopBar {
    display: none;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
