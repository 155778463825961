div#training {
  background: #424242;
  color: #ccc;
  padding: 30px;
}

/* Section Title Text */
.text-dyebold {
  color: #b58707;
  text-transform: uppercase;
  font-size: 36px;
}

h4.title-top {
  letter-spacing: 5px;
  font-family: cursive;
}

h4.subtitle {
  font-family: system-ui;
  font-size: 20px;
  line-height: 52px;
  color: #c2c2c2;
}
@media only screen and (min-width: 1200px) {
}

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {
  .text-dyebold {
    color: #b58707;
    text-transform: uppercase;
    font-size: 22px;
  }

  #training h4.title-top {
    letter-spacing: 0px;
    font-family: cursive;
  }
  #training h4.subtitle {
    font-size: 16px;
    line-height: 25px;
  }

  .space-gap {
    padding: 5px 0;
  }

  #company .desc {
    margin-top: 20px;
  }

  div#training {
    margin-top: 230px;
  }
}
