.footer-container h2,
.footer-container h5,
.footer-container a {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.footer-container {
  background-color: #242424;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
  font-size: 1.5rem;
}

.footer-link-items > h2 {
  color: #ffc107;
}

.footer-links h5 {
  color: #fff;
  font-size: 1rem;
}

.footer-link-items a {
  font-size: 1rem;
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-logo {
  padding-top: 30px;
}

.footer-link-items .social-media {
  max-width: 1000px;
  width: 100%;
  border-top: 1px solid #ccc;
  padding: 15px;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 11px;
  border-radius: 30%;
  width: 30px;
  text-align: center;
  height: 30px;
}

a.social-icon-link {
  padding-top: 6px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.small,
small {
  font-size: 0.875em;
  font-family: sans-serif;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
  margin-top: -40px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
  font-size: 0.7rem;
}
@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .footer-container .social-icons {
    margin-top: 0px !important;
  }
  .footer-container img.top.img-fluid {
    width: 100px;
  }
  .footer-container {
    padding: 0;
  }
  small.website-rights {
    font-size: 10px;
  }
  .footer-container .social-icon-link {
    font-size: 12px;
    padding: 8px;
  }
  .footer-container h2 {
    font-size: 1rem;
  }
  .footer-container h5,
  .footer-container a {
    font-size: 0.8rem;
  }

  .icon-bar a {
    display: block;
    text-align: center;
    padding: 10px;
    transition: all 0.3s ease;
    color: white;
    font-size: 10px;
  }
  .footer-container .social-media-wrap {
    margin-bottom: 20px;
  }
}
