div#company {
  background: #023646;
  color: #ccc;
  background-image: url("https://dyebold.com/wp-content/uploads/2022/10/rug-restoration.webp");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: cover;
}

.overlay {
  background-color: #000000;
  opacity: 0.75;
  transition: 0.3s, border-radius 0.3s, opacity 0.3s;
}
.padding {
  padding: 40px;
}

h4.company-subtitle {
  font-family: system-ui;
  font-size: 72px;
  line-height: 55px;
}

@media only screen and (max-width: 1200px) {
  h4.company-subtitle {
    font-size: 48px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 992px) {
  h4.company-subtitle {
    font-size: 36px;
    line-height: 36px;
  }
}

@media only screen and (max-width: 768px) {
  h4.company-subtitle {
    font-size: 28px;
    line-height: 28px;
  }

  .text-best-choice {
    color: #ffffff;
    font-family: "Montserrat", Sans-serif;
    font-size: 26px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 40px;
  }
}
