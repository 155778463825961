div#academy #page-top-bg {
  background: #023646;
  color: #ccc;
  background-image: url("https://dyebold.com/wp-content/uploads/2022/10/rug-restoration.webp");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: cover;
  height: 40vh;
}

div#academy #page-top-bg .overlay {
  background-color: #000000;
  opacity: 0.6;
  transition: 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 40vh;
}

.page-title,
.page-subtitle {
  padding: 45px 12px;
}

#academy h4.title-top {
  letter-spacing: 0px;
  font-family: cursive;
}

#academy div#dyebold-sec {
  background: #ffc107;
  padding: 30px 0;
  color: #fff;
}
#academy .dyebold-red {
  color: #fff;
  background-color: #6a0303 !important;
  border-color: #6a0303 !important;
  background: #6a0303 !important;
}

#academy .gray-bg {
  background: #e7e7e7;
}
#academy .dyebold-bg {
  background: #e9bf49;
}

#academy.section-description {
  padding: 30px 0;
}
