* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  /* background-image: url('/images/img-2.jpg');*/
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  /* background-image: url('/images/img-1.jpg'); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  /* background-image: url('/images/img-8.jpg');*/
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

/* New Style For Home page */
.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 0px;
  border: 1px solid transparent;
}

h1.sec-title {
  color: #0074ab;
}

.navbar {
  position: fixed;
  min-height: 50px;
  margin-bottom: 0px;
  border: 1px solid transparent;
  width: 100%;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

p {
  margin: 0 0 10px;
  font-size: 16px;
}

div#ourworks {
  background: #020202;
  color: #ccc;
}

.cards__item__text {
  color: #8e8e8e !important;
  font-size: 18px;
  line-height: 24px;
  /* text-decoration: none; */
}

#products .cards {
  padding: 7rem !important;
  background: #fff;
}

a:focus,
a:hover {
  color: #ecba04;
  text-decoration: underline;
}

.btn.focus,
.btn:focus,
.btn:hover {
  color: #ecba04;
  text-decoration: none;
}

#page-adj {
  margin-top: 80px;
  padding: 50px 0;
}

img.ceo {
  border: 5px solid #ccc;
}

/* Side Social Bar */

.icon-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar a:hover {
  background-color: #000;
}

.facebook {
  background: #3b5998;
  color: white;
}

.twitter {
  background: #55acee;
  color: white;
}

.google {
  background: #dd4b39;
  color: white;
}

.linkedin {
  background: #007bb5;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}

.content {
  margin-left: 75px;
  font-size: 30px;
}

/* Image border */
.img-border {
  vertical-align: middle;
  border-style: solid;
  border-width: 4px 4px 4px 4px;
  border-color: #e9bf49;
  border-radius: 7px 7px 7px 7px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 55vw;
  justify-content: end;
  margin-right: 2rem;
  display: inherit;
}

/* New Menu Code */
a.nav-link {
  color: #fff !important;
}

.nav-link:focus,
.nav-link:hover {
  color: rgb(255 193 7) !important;
  text-decoration: none;
}

/* nav a.active {
  background-color: green;
  color: #fff;
  padding: 5px;
} */

.navbar-light .navbar-toggler-icon {
  background-color: #605757;
}

.navbar-light .navbar-toggler {
  color: rgb(255 193 7);
  border-color: rgb(255 193 7);
}

.footer-container h4 {
  color: #c2c2c2;
}
.subtitl,
.title-top {
  color: #c2c2c2;
}

#BestChoice h4 {
  color: #080808;
}
