.black {
  background-color: #000;
  border-radius: 10px;
  opacity: 0.8;
}
.dyebold {
  background-color: #ffc107;
  border-radius: 10px;
  opacity: 0.8;
}

.cards,
.black,
.dyebold {
  position: relative;
}

div#aboutarea {
  background: #fff;
}

p.about {
  line-height: 33px;
  font-family: cursive;
  text-align: center;
  font-size: 14px;
  padding: 30px;
}

.dyebold p.card-text {
  color: #000;
}

p.card-text {
  padding: 20px 0;
  line-height: 38px;
  color: #fff;
}

.card-body {
  height: 380px;
}

@media only screen and (max-width: 768px) {
  .card-body {
    height: 300px;
    text-align: center;
  }
}

@media only screen and (min-width: 992px) {
}

@media only screen and (max-width: 1200px) {
  .card-body {
    text-align: center;
  }
}
