#dyeing .margin-t-top {
  margin-top: 30px;
}

#dyeing h3 {
  color: #e9bf49;
}

div#dyeing #page-top-bg {
  background: #023646;
  color: #ccc;
  background-image: url("https://dyebold.com/wp-content/uploads/2022/10/rug-restoration.webp");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: cover;
  height: 40vh;
}

div#dyeing #page-top-bg .overlay {
  background-color: #000000;
  opacity: 0.6;
  transition: 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 40vh;
}

.page-title,
.page-subtitle {
  padding: 45px 12px;
}
