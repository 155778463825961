.padding {
  padding: 40px;
}

div#professional {
  background: white;
}
.professional-img {
  background: url("../../public/images/professional.jpg") no-repeat;
  width: 100%;
  height: 100%;
}

img.pro-img {
  z-index: 2;
  position: absolute;
}

#professional .text-dyebold {
  color: #b58707;
  text-transform: uppercase;
  font-size: 22px;
}
